<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="start" class="">
                <v-col cols="auto" class="m-0 p-0 me-2">
                  <v-avatar dark :color="hexTints(area.cor,0.8)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0 ps-1">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    Ementa Original
                    <span class="mx-1 fs-7pt fw-200">•</span>
                    <span class="fs-10pt f-roboto fw-300">{{conteudosParsed.length}} conteúdos extraidos</span>
                  </div>
                  <div class="line-height-0" v-if="uc.ementa.createdAt != undefined" :style="'color:'+hexShades(area.cor,.6)">
                    <i v-i:duo#clock#12 class="left mt-0" :style="'color:'+hexShades(area.cor,.6)"></i>
                    <span class="left ms-1 mt-2 f-lato fs-8pt fw-300">atualizado {{$moment(uc.ementa.createdAt).fromNow()}}</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card :color="hexTints(area.cor,0.98)">
            <v-container fluid class="">
              <v-row dense>
                <v-col cols="12" class="m-0 p-0 mt-1">
                  <p class="m-0 mb-1 p-0 fs-10pt f-lato fw-600">Texto original da ementa</p>
                  <v-textarea rows="8" solo dense hide-details class="fs-10pt f-lato text-justify line-height-0" v-model="ementaTexto"></v-textarea>
                  <v-container fluid class="m-0 p-0 my-4">
                    <v-row dense align="center" justify="space-between">
                      <v-col cols="auto">
                        <p class="m-0 mb-1 p-0 fs-10pt f-lato fw-600">Conteúdos extraidos do texto original da ementa</p>
                      </v-col>
                      <v-col cols="3">
                        <v-btn color="" class="ps-0 pe-1 fs-8pt f-lato text-nonecase" outlined x-small @click="$refs.promptdialogref.show()">
                          <i v-i:duo#plus-circle#18 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                          adicionar todos
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <conteudoitemselect :conteudos="conteudos" :area="area" :uc="uc" :item="item" v-for="(item,index) in conteudosParsed" :key="uc.id+index+refresh" />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card>
      </v-col>
      <promptdialogtodos ref="promptdialogref" @confirm="adicionarTodos"/>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponent/feedControler.vue'
import descontinuarbtn from '@/views/cursos/planos/descontinuarBtn.vue'
import moment from 'moment';
import spanblocked from "./spanBlocked.vue"
import ementaModel from "./ementa-model.js";

export default {
  name: "ementaView",
  components: { readmore, feedcontroler, descontinuarbtn, spanblocked,
    'promptdialogtodos': () => import('./promptdialogtodos.vue'),
    'conteudoitemselect': () => import('./conteudo-item-select.vue'),
  },
  props: {
    noedit: { default: false, type: Boolean },
    parse: { default: false, type: Boolean },
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {
  },
  data(){
    return {
      ementaPath: null,
      descontinuados: [],
      refresh: 0,
      ementaTexto: "",
      conteudosParsed: [],
      conteudos: {},
    }
  },
  watch: {
    uc() {
      this.ementaTexto = this.uc.ementa.texto;
    },
    ementaTexto() {
      console.log("ementaTexto",this.ementaTexto);
      this.parseEmenta();
    }
  },
  mounted: function() {
    var self = this;
    rdb.ref('/curriculo/conteudos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var uc = snapshot.val();
      if(uc == null) {
        self.conteudos = {};
      } else {
        self.conteudos = uc.conteudos;
      }
      console.log("self.conteudos",self.conteudos);
      self.buildEmenta();
    });
  },
  methods:{

    buildEmenta() {
      var self = this;
      if(this.uc != null) {
        this.ementaTexto = this.uc.ementa.texto;
        this.parseEmenta();
      }
      self.refresh++;
    },

    parseEmenta() {
      var self = this;
      self.conteudosParsed = [];
      var vet = this.ementaTexto.trim().split(";");
      if(vet.length < 2) {
        vet = this.ementaTexto.trim().split(".");
      }

      if(vet.length > 2) {
        for(var idx in vet) {
          var conteudo = vet[idx].trim();
          if(conteudo.charAt(conteudo.length-1) == ".") conteudo = conteudo.substr(0,conteudo.length-1);
          if(conteudo.charAt(conteudo.length-1) == ";") conteudo = conteudo.substr(0,conteudo.length-1);
          if(conteudo.trim() != "") {
            self.conteudosParsed.push(conteudo);
          }
        }
      }
      this.refresh++;
      //console.log("self.conteudosParsed",self.conteudosParsed);

    },

    adicionarTodos() {
      console.log("adicionarTodos");
      //console.log("this.conteudosParsed",this.conteudosParsed);
      //console.log("this.conteudos",this.conteudos);
      console.log("this.uc",this.uc);
      console.log("this.area",this.area);
      var conteudosAdd = [];
      for(var key in this.conteudosParsed) {
        var texto = this.conteudosParsed[key].trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        var achei = false;
        for(var key2 in this.conteudos) {
          var texto2 = this.conteudos[key2].texto.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          if(texto == texto2) {
            console.log("achei: ",key2);
            achei = true;
            break;
          }
        }
        if(!achei) {
          conteudosAdd.push(this.conteudosParsed[key]);
          //ementaModel.add({ uc: this.uc, texto: this.conteudosParsed[key].trim() });
        }
      }

      console.log("conteudosAdd",conteudosAdd);
      this.addConteudo(conteudosAdd);
    },

    addConteudo(vet) {
      var self = this;
      if(vet.length > 0) {
        var item = vet.shift();
        console.log("addConteudo",item);
        ementaModel.add({ uc: this.uc, texto: item.trim() },function() {
            self.addConteudo(vet);
        });
      }
    },

  }
}
</script>

<style>

.v-textarea textarea {
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.3rem;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 2px 10px 2px 4px;
  width: 100%;
  text-align: justify;
}
</style>
