import { rdb, snapshotValToArray, ServerValue } from '@/firebasedb.js';
import moment from "moment";

export default {
  add(value,cbfunc) {
    console.log("add",value);
    //rdb.ref("/curriculo/conteudos/contadores/proxID").set(ServerValue.increment(1));
    rdb.ref("/curriculo/conteudos/contadores/total").set(ServerValue.increment(1));
    //rdb.ref("/curriculo/conteudos/contadores/").update({proxID: ServerValue.increment(1)});
    rdb.ref("/curriculo/conteudos/contadores/proxID").get().then(function(snap) {
      var proxID = snap.val();
      console.log("proxID",proxID);
      rdb.ref("/curriculo/conteudos/contadores/proxID").set(ServerValue.increment(1));
      if(proxID == null) {
        proxID = 1;
      }
      var updates = {};
      var newKey = "CTD"; //generateConteudoID(value.areaSigla,value.ucNome);
      var num = ""+proxID;
      while (num.length < 5) num = "0" + num;
      newKey = newKey + "-" + num;
      updates["/curriculo/conteudos/conteudos/"+newKey+"/id"] = newKey;
      updates["/curriculo/conteudos/conteudos/"+newKey+"/texto"] = value.texto;
      updates["/curriculo/conteudos/conteudos/"+newKey+"/createdAt"] = moment().format();
      updates["/curriculo/conteudos/conteudos/"+newKey+"/ucs/"+value.uc.id] = { id: value.uc.id, nome: value.uc.nome };
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/id"] = value.uc.id;
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/nome"] = value.uc.nome;
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/id"] = newKey;
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/texto"] = value.texto;
      console.log("updates",updates);
      rdb.ref().update(updates);
      rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id).update({qtde: ServerValue.increment(1)});
      rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").get().then(function(snap) {
        var ordem = snap.val();
        if(ordem == null) {
          ordem = newKey;
        } else {
          ordem = ordem+";"+newKey;
        }
        rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").set(ordem);
        cbfunc();
      });
    });
  },

  editarTexto(conteudo,uc) {
    console.log("editarTexto");
    console.log("conteudo",conteudo);
    console.log("uc",uc);
    rdb.ref("/curriculo/conteudos/conteudos/"+conteudo.id+"/texto").set(conteudo.texto.trim());
    rdb.ref("/curriculo/conteudos/conteudos/"+conteudo.id).get().then(function(snap) {
      console.log("snap",snap.val());
      var elem = snap.val();
      if(elem != null) {
        var topicos = elem.topicos;
        if(topicos != undefined) {
          for(var tpkey in topicos) {
            rdb.ref("/curriculo/topicos/topicos/"+tpkey+"/conteudos/"+conteudo.id+"/texto").set(conteudo.texto.trim());
          }
        }
        var ucs = elem.ucs;
        if(ucs != undefined) {
          for(var uckey in ucs) {
            rdb.ref("/curriculo/conteudos/ucs/"+uckey+"/conteudos/"+conteudo.id+"/texto").set(conteudo.texto.trim());
          }
        }
      }
    });
  },

  update(value) {
    console.log("update",value);
    var updates = {};
    var newKey = value.conteudo.id;
    updates["/curriculo/conteudos/conteudos/"+newKey+"/ucs/"+value.uc.id] = { id: value.uc.id, nome: value.uc.nome };
    updates["/curriculo/conteudos/ucs/"+value.uc.id+"/id"] = value.uc.id;
    updates["/curriculo/conteudos/ucs/"+value.uc.id+"/nome"] = value.uc.nome;
    updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/id"] = newKey;
    updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/texto"] = value.conteudo.texto;
    console.log("updates",updates);
    // AUD-MecSisAgrPre-0001
    // AUD-MecSAP-0001
    rdb.ref().update(updates);
    rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id).update({qtde: ServerValue.increment(1)});
    rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").get().then(function(snap) {
      var ordem = snap.val();
      if(ordem == null) {
        ordem = newKey;
      } else {
        ordem = ordem+";"+newKey;
      }
      rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").set(ordem);
    });
  },

  remove(value,uc) {
    console.log("remove",value);
    rdb.ref("/curriculo/conteudos/conteudos/"+value.id).get().then(function(snap) {
      console.log("snap",snap.val());
      var conteudo = snap.val();
      if(conteudo != null) {
        var topicos = conteudo.topicos;
        if(topicos != undefined) {
          for(var tpkey in topicos) {
            rdb.ref("/curriculo/topicos/topicos/"+tpkey+"/conteudos/"+conteudo.id).set(null);
          }
        }
        if(Object.keys(conteudo.ucs).length == 1) {
          rdb.ref("/curriculo/conteudos/conteudos/"+value.id).set(null);
          rdb.ref("/curriculo/conteudos/contadores/").update({total: ServerValue.increment(-1)});
        } else {
          rdb.ref("/curriculo/conteudos/conteudos/"+value.id+"/ucs/"+uc.id).set(null);
        }
        rdb.ref("/curriculo/conteudos/ucs/"+uc.id+"/conteudos/"+value.id).set(null);
        rdb.ref("/curriculo/conteudos/ucs/"+uc.id).update({qtde: ServerValue.increment(-1)});
        rdb.ref("/curriculo/conteudos/ucs/"+uc.id+"/ordem").get().then(function(snap) {
          var ordem = snap.val();
          if(ordem != null) {
            var keys = ordem.split(";");
            if(keys.length == 1) {
              rdb.ref("/curriculo/conteudos/ucs/"+uc.id+"/ordem").set(null);
            } else {
              ordem = ordem.replaceAll(value.id+";","");
              ordem = ordem.replaceAll(";"+value.id,"");
              ordem = ordem.replaceAll(value.id,"");
              rdb.ref("/curriculo/conteudos/ucs/"+uc.id+"/ordem").set(ordem);
            }
          }
        });
      }
    });
    return;

    rdb.ref("/curriculo/conteudos/contadores/").update({total: ServerValue.increment(-1)});
    var updates = {};
    updates["/curriculo/conteudos/conteudos/"+value.id] = null;
    console.log("updates",updates);
    rdb.ref().update(updates);
  },

}
